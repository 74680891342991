export const useHeaderStore = defineStore('headerStore', () => {
  const isActiveSuggest = ref<boolean>(false);
  const isSearchOpened = ref<boolean>(false);
  const isVoiceSearchOpened = ref<boolean>(false);

  return {
    isActiveSuggest,
    isSearchOpened,
    isVoiceSearchOpened,
  };
});
