<template>
  <HeaderTop />

  <header
    :class="[
      'header',
      {
        'is-search': isSearchOpened || isVoiceSearchOpened,
      },
    ]"
  >
    <div class="container">
      <div class="header__buttons header__buttons--left">
        <Button
          class="header__menu"
          variant="link"
          iconName="menu"
          iconOnly
          :aria-label="$t('ariaLabel.openMenu')"
          @click="openSideMenuMobile"
        />

        <Button
          class="header__search"
          variant="link"
          iconName="search"
          iconOnly
          :aria-label="$t('ariaLabel.openSearch')"
          @click="openSearch"
        />
      </div>

      <LocaleLink
        to="/"
        class="header__logo"
      >
        <img
          :src="'/images/logo.svg'"
          :alt="$t('logoAlt')"
          :title="$t('logoAlt')"
          width="133"
          height="40"
        />
      </LocaleLink>

      <ButtonCatalog
        class="header__catalog"
        size="lg"
      />

      <div class="header__search-block">
        <Search
          @search:closed="closeSearch"
          @search:opened="openSearch"
        />
      </div>

      <div class="header__contacts">
        <a
          v-if="store?.storePhone"
          :href="`tel:${store.storePhone}`"
          class="header__contacts-telephone"
        >
          {{ store.storePhone }}
        </a>

        <div
          v-if="store?.storeHours"
          class="header__contacts-hours"
        >
          {{ store.storeHours }}
        </div>
      </div>

      <div class="header__buttons">
        <Button
          :to="isAuth ? localePath('/cabinet/account') : undefined"
          :class="[
            'header__auth',
            {
              'is-auth': isMounted && isAuth && !pendingAuth,
              'has-avatar': isMounted && isAuth && !pendingUser && !!userAvatar,
            },
          ]"
          variant="link"
          :iconOnly="isMounted"
          :aria-label="$t('ariaLabel.openPersonalCabinet')"
          :iconName="isMounted && !userAvatar ? (isAuth ? 'user' : 'user-o') : undefined"
          :loading="!isMounted || pendingUser"
          @click.prevent="!isAuth ? openAuthModal() : undefined"
        >
          {{ isMounted ? userAvatar : '' }}
        </Button>

        <Button
          :to="isAuth ? localePath('/cabinet/wishlist') : undefined"
          class="header__favorite"
          variant="link"
          iconName="favorite"
          iconOnly
          :aria-label="$t('ariaLabel.openWishlist')"
          :loading="!isMounted || pendingWishlist || pendingUser"
          @click.prevent="!isAuth ? openAuthModalWishlist() : undefined"
        >
          <ClientOnly>
            <Badge
              v-if="isAuth && wishlist?.productsCount"
              class="header-button__badge"
              size="sm"
              variant="primary"
            >
              {{ wishlist.productsCount }}
            </Badge>
          </ClientOnly>
        </Button>

        <Button
          class="header__cart"
          variant="link"
          iconName="cart"
          iconOnly
          :aria-label="$t('ariaLabel.openCart')"
          :loading="!isMounted || pendingCart"
          @click.prevent="openCart"
        >
          <ClientOnly>
            <Badge
              v-if="cart?.productsQuantityCount"
              class="header-button__badge"
              size="sm"
              variant="primary"
            >
              {{ cart.productsQuantityCount }}
            </Badge>
          </ClientOnly>
        </Button>
      </div>
    </div>
  </header>
</template>

<script lang="ts" setup>
import HeaderTop from '../ui/HeaderTop.vue';
import { LocaleLink } from '@shared/LocaleLink';
import { Button } from '@shared/Button';
import { ButtonCatalog } from '@entities/ButtonCatalog';
import { Badge } from '@shared/Badge';
import { Search } from '@features/Search';

const { trackViewedCart } = useAnalytics();

const { openSideMenuMobile } = useSideNav();

const { openModalCart } = useCart();

const { openAuthModal } = useAuth();

const localePath = useLocalePath();

const { t: $t } = useI18n();

const { store } = storeToRefs(useMainStore());

const { isSearchOpened, isVoiceSearchOpened } = storeToRefs(useHeaderStore());

const { pending: pendingCart, cart } = storeToRefs(useCartStore());

const { pending: pendingAuth, isAuth } = storeToRefs(useAuthStore());

const { pending: pendingUser, userAvatar } = storeToRefs(useUserStore());

const { pending: pendingWishlist, wishlist } = storeToRefs(useWishlistStore());

const isMounted = ref(false);

const openSearch = () => {
  isSearchOpened.value = true;
};

const closeSearch = () => {
  isSearchOpened.value = false;
  isVoiceSearchOpened.value = false;
};

const openCart = () => {
  openModalCart();
  trackViewedCart();
};

const openAuthModalWishlist = () => {
  openAuthModal(() => navigateTo(localePath('/cabinet/wishlist')));
};

onMounted(() => {
  isMounted.value = true;
});
</script>

<style lang="scss" src="./Header.scss" />
