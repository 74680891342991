<template>
  <button
    v-if="isSupported"
    type="button"
    class="speech-recognition-btn"
    @click="getMicrophonePermission"
  >
    <Icon name="voice" />
  </button>
</template>

<script setup lang="ts">
import { Icon } from '@shared/Icon';

const $emit = defineEmits(['result']);

const { locale, locales } = useI18n();
const language = computed(() => locales.value.find((loc) => loc.code === locale.value)?.language);

const isSupported = ref<boolean>(false);
const isFinal = ref<boolean>(false);
const result = ref<string>('');

let startRecognition: () => void;

let stopRecognition: () => void;

const initSpeechRecognition = (lang: string) => {
  const {
    isSupported: supported,
    isFinal: final,
    result: res,
    start,
    stop,
  } = useSpeechRecognition({
    lang,
    interimResults: true,
    continuous: true,
  });

  isSupported.value = supported.value;
  isFinal.value = final.value;
  result.value = res.value;
  startRecognition = start;
  stopRecognition = stop;

  watch(final, (value: boolean) => (isFinal.value = value));
  watch(res, (value: string) => (result.value = value));
};

watch(
  locale,
  () => {
    if (language.value) {
      initSpeechRecognition(language.value);
    }
  },
  { immediate: true }
);

const { isActiveSuggest, isVoiceSearchOpened } = storeToRefs(useHeaderStore());
watch(isVoiceSearchOpened, (value: boolean) => {
  if (!value) {
    stopListening();
  } else if (isActiveSuggest.value) {
    isActiveSuggest.value = false;
  }
});

const startListening = (): void => {
  startRecognition();
  isVoiceSearchOpened.value = true;
};
const stopListening = (): void => {
  stopRecognition();
  stopMicrophoneStream();
};

watch(isFinal, (value: boolean) => {
  if (value) {
    isVoiceSearchOpened.value = false;
    $emit('result', result.value);
  }
});

const mediaStream = ref<MediaStream | null>(null);
const startMicrophoneStream = async (): Promise<void> => {
  try {
    mediaStream.value = await navigator.mediaDevices.getUserMedia({ audio: true });
    startListening();
  } catch (error) {
    alert('Microphone permission denied or not available.');
  }
};
const stopMicrophoneStream = (): void => {
  if (mediaStream.value) {
    mediaStream.value.getTracks().forEach((track) => track.stop());
    mediaStream.value = null;
  }
};

const getMicrophonePermission = async (): Promise<void> => {
  if (isSupported.value) {
    await startMicrophoneStream();
  } else {
    alert('Speech recognition is not supported in this browser.');
  }
};
</script>

<style lang="scss" src="./SpeechRecognitionBtn.scss" />
