<template>
  <Teleport to="body">
    <Transition name="fade">
      <div
        v-if="isSearchOpened || isVoiceSearchOpened || isActiveSuggest"
        class="overlay"
        style="z-index: 98"
        @click="closeSearch"
      />
    </Transition>
  </Teleport>

  <div
    ref="target"
    :class="['search', { 'is-active': isSearchOpened || isVoiceSearchOpened }]"
  >
    <form
      class="search__form"
      @submit.prevent="handleSubmit"
    >
      <Input
        ref="searchInputField"
        v-model.trim="searchPhrase"
        :placeholder="$t('search.book')"
        type="search"
        block
        @click="openSearch"
        @input="fetchSearch"
      />

      <div
        v-if="pending"
        class="search__loader"
      />

      <button
        v-else
        type="button"
        class="search__action search__action--back"
        @click.prevent="closeSearch"
      >
        <Icon name="arrow-thin-left" />
      </button>

      <div class="search__actions">
        <Transition name="fade">
          <button
            v-if="hasSearchPhrase"
            type="button"
            class="search__action search__action--clear"
            @click.prevent="clearSearch"
          >
            <Icon name="close" />
          </button>
        </Transition>

        <ClientOnly>
          <SpeechRecognitionBtn
            class="search__action"
            @result="searchPhrase = $event"
          />
        </ClientOnly>
      </div>

      <Transition name="fade">
        <div
          v-if="pending"
          class="search__loader"
        />
      </Transition>

      <button
        type="submit"
        class="search__submit"
      >
        <Transition name="fade">
          <Icon
            v-if="!pending"
            name="search"
          />
        </Transition>
      </button>
    </form>

    <Transition name="fade">
      <div
        v-if="isActiveSuggest"
        class="search__suggest"
      >
        <template v-if="!isEmptySuggest">
          <Button
            variant="link"
            block
            class="search__suggest-more"
            @click.prevent="handleSubmit"
          >
            {{ $t('search.all') }}
            <template #icon>
              <Icon name="arrow-thin-right" />
            </template>
          </Button>

          <div class="search__suggest-content">
            <template v-if="searchResult.categories?.length">
              <div
                v-for="item in searchResult.categories"
                :key="item.urlPath"
                class="search__suggest-item"
                @click="closeSearch"
              >
                <LocaleLink
                  :to="{ path: `/books/${item.urlPath}` }"
                  class="search__suggest-link"
                >
                  {{ item.name }}
                </LocaleLink>
              </div>
            </template>

            <template v-if="searchResult.products?.length">
              <div
                v-for="item in searchResult.products"
                :key="item.id"
                class="search__suggest-item"
                @click="closeSearch"
              >
                <SuggestTile :data="item" />
              </div>
            </template>
          </div>
        </template>

        <div
          v-else
          class="search__empty"
        >
          {{ $t('search.empty') }}
        </div>
      </div>
    </Transition>

    <Transition name="fade">
      <VoiceRecording
        v-if="isVoiceSearchOpened"
        class="search__voice-recording"
        @close="closeSearch"
      />
    </Transition>
  </div>
</template>

<script lang="ts" setup>
import type { IProduct } from '@types';
import { Icon } from '@shared/Icon';
import { Input } from '@shared/Input';
import { Button } from '@shared/Button';
import { LocaleLink } from '@shared/LocaleLink';
import { SuggestTile } from '@entities/SuggestTile';
import { VoiceRecording } from '@features/VoiceRecording';
import { SpeechRecognitionBtn } from '@features/SpeechRecognitionBtn';

interface ICategories {
  name: string;
  urlPath: string;
}

interface ISearchResult {
  categories: ICategories[];
  products: IProduct[];
}

const MIN_PHRASE_LENGTH = 2;

const $emit = defineEmits(['search:opened', 'search:closed']);

const { trackSearch } = useAnalytics();

const $router = useRouter();

const localePath = useLocalePath();

const { t: $t } = useI18n();

const { isActiveSuggest, isSearchOpened, isVoiceSearchOpened } = storeToRefs(useHeaderStore());

const abortController = ref<any>(null);

const pending = ref<boolean>(false);
const target = ref<HTMLElement | null>(null);
const isEmptySuggest = ref<boolean>(false);

const searchInputField = ref<any>(null);

const searchPhrase = ref<string>();
const hasSearchPhrase = computed(
  (): boolean => !!(searchPhrase.value?.length && searchPhrase.value.length >= MIN_PHRASE_LENGTH)
);

const searchResult = reactive<ISearchResult>({
  categories: [],
  products: [],
});

const handleSubmit = () => {
  if (!hasSearchPhrase.value) {
    return;
  }

  closeSearch();

  $router.push({
    path: localePath('/search'),
    query: { search: encodeURIComponent(searchPhrase.value as string) },
    force: true,
  });
};

const closeSearch = (): void => {
  isActiveSuggest.value = false;

  searchInputField.value.inputRef?.blur();

  $emit('search:closed');
};

const openSearch = (): void => {
  checkRequestResultData();

  $emit('search:opened');
};

const clearSearch = (): void => {
  searchResult.categories = [];
  searchResult.products = [];
  isActiveSuggest.value = false;

  searchPhrase.value = '';

  closeSearch();
};

const checkEmptyResult = (): void => {
  isEmptySuggest.value = !searchResult.categories?.length && !searchResult.products?.length;
};

const checkRequestResultData = (): void => {
  if (searchResult.categories?.length || searchResult.products?.length) {
    isActiveSuggest.value = true;
  }
};

const fetchSearch = async ($event: Event): Promise<void> => {
  searchPhrase.value = ($event.target as HTMLInputElement).value;

  if (abortController.value) {
    abortController.value.abort();
  }

  if (!hasSearchPhrase.value) {
    pending.value = false;
    return;
  }

  const abortControllerNew = new AbortController();
  abortController.value = abortControllerNew;

  pending.value = true;

  try {
    const { categories = [], products = [] } = await fetchApiGet('/autocomplete', {
      signal: abortControllerNew.signal,
      data: {
        phrase: encodeURIComponent(searchPhrase.value as string),
      },
    });

    trackSearch({ searchPhrase: searchPhrase.value });

    searchResult.categories = categories;
    searchResult.products = products;

    isActiveSuggest.value = true;
  } catch {
  } finally {
    if (!abortControllerNew.signal.aborted) {
      pending.value = false;
    }

    checkEmptyResult();
  }
};

useEventListener(document, 'keydown', (e) => {
  if (e?.code?.toLowerCase() === 'escape') {
    e.preventDefault();
    closeSearch();
  }
});

onClickOutside(target, () => {
  if (isSearchOpened.value || isVoiceSearchOpened.value) {
    closeSearch();
  }
});

watch([isSearchOpened, isVoiceSearchOpened], ([newIsSearchOpened, newIsVoiceSearchOpened]) => {
  if (newIsSearchOpened || newIsVoiceSearchOpened) {
    document.documentElement.classList.add('is-overlay');
  } else {
    document.documentElement.classList.remove('is-overlay');
  }
});

watchEffect(() => {
  if (isSearchOpened.value && searchInputField.value?.inputRef) {
    searchInputField.value.inputRef?.focus();
  }
});
</script>

<style lang="scss" src="./Search.scss" />
