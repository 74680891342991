<template>
  <div class="social-links">
    <a
      v-for="icon in iconsLinks"
      :key="icon.label"
      :href="icon.href"
      target="_blank"
      rel="nofollow noopener"
      class="social-links__item"
      :aria-label="icon.label"
    >
      <Icon
        :name="icon.label"
        class="social-links__icon"
        :alt="icon.label"
      />
    </a>
  </div>
</template>

<script lang="ts" setup>
import { Icon } from '@shared/Icon';

const iconsLinks = reactive([
  {
    label: 'telegram',
    href: 'https://t.me/bookua_support_bot',
  },
  {
    label: 'tiktok',
    href: 'https://www.tiktok.com/@bookua_store',
  },
  {
    label: 'facebook',
    href: 'https://www.facebook.com/book.ua',
  },
  {
    label: 'instagram',
    href: 'https://www.instagram.com/bookua.store/',
  },
]);
</script>

<style lang="scss" src="./SocialLinks.scss" />
