<template>
  <div class="subscription-news">
    <h3
      v-if="!isSuccessSubscription"
      class="subscription-news__title"
    >
      {{ $t('subscription.titleOfFooter') }}
    </h3>

    <form
      v-if="!isSuccessSubscription"
      class="subscription-news__form"
      @submit.prevent="submitSubscription"
    >
      <FormGroup
        name="email"
        :error="errors.email"
        class="subscription-news__form-group"
      >
        <Input
          v-model="email"
          :placeholder="$t('email')"
          size="lg"
          @input="setErrors({ email: undefined })"
        />
      </FormGroup>

      <Button
        class="subscription-news__submit"
        type="submit"
        variant="secondary"
        size="lg"
        :loading="pending"
      >
        {{ $t('subscription.btnSignUp') }}
      </Button>
    </form>
    <ThankSubscribing v-else />
  </div>
</template>

<script setup lang="ts">
import { useForm } from 'vee-validate';
import * as yup from 'yup';
import { Input } from '@shared/Input';
import { Button } from '@shared/Button';
import { FormGroup } from '@shared/FormGroup';
import { ThankSubscribing } from '@entities/ThankSubscribing';
import { VALIDATORS } from '@constants';

const { t: $t } = useI18n();

const { pending, isSuccessSubscription, fetchGuestNewsletter } = useSubscription();

const { user } = storeToRefs(useUserStore());

const { defineField, errors, setErrors, validateField } = useForm({
  validationSchema: yup.object({
    email: yup
      .string()
      .required($t('error.email.required'))
      .matches(VALIDATORS.email, $t('error.email.invalid'))
      .label($t('email')),
  }),
});

const [email] = defineField('email', {
  validateOnChange: false,
  validateOnModelUpdate: false,
});

watch(
  user,
  (data: any) => {
    if (data) {
      email.value = data.email || '';
    } else {
      email.value = '';
    }
  },
  {
    deep: true,
    immediate: true,
  }
);

const submitSubscription = async (): Promise<void> => {
  const { valid } = await validateField('email');

  if (!valid) {
    return;
  }

  pending.value = true;

  try {
    await fetchGuestNewsletter({ email: email.value });
    isSuccessSubscription.value = true;
  } catch (error: any) {
    setErrors({
      email: error?.message,
    });
  } finally {
    pending.value = false;
  }
};
</script>

<style lang="scss" src="./SubscriptionNews.scss" />
