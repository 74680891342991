<template>
  <LocaleLink
    :to="productLink"
    class="suggest-tile"
  >
    <figure
      v-if="data?.image?.xxs"
      class="suggest-tile__figure"
    >
      <img
        :src="data.image.xxs"
        :alt="data.name"
        :title="data.name"
        loading="lazy"
      />
    </figure>

    <div class="suggest-tile__content">
      <div class="suggest-tile__title">{{ data.name }}</div>

      <div class="suggest-tile__footer">
        <Price
          :val="valuePrice"
          :valOld="valuePriceOld"
          :hasInStock="hasInStock"
          size="xxs"
        />

        <ProductStock
          class="suggest-tile__stock"
          :hasInStock="hasInStock"
        />
      </div>
    </div>
  </LocaleLink>
</template>

<script lang="ts" setup>
import type { IProduct } from '@types';
import { LocaleLink } from '@shared/LocaleLink';
import { Price } from '@shared/Price';
import { ProductStock } from '@entities/ProductStock';

interface IProps {
  data: IProduct;
}

const props = defineProps<IProps>();

const { productLink, hasInStock, valuePrice, valuePriceOld } = useProductProperties(props.data);
</script>

<style lang="scss" src="./SuggestTile.scss" />
