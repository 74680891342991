<template>
  <div class="voice-recording">
    <div class="voice-recording__microphone">
      <Icon
        name="voice"
        size="40px"
      />
    </div>

    <h1 class="h1 voice-recording__title">
      {{ $t('search.speak') }}
    </h1>

    <div class="voice-recording__dots">
      <span class="voice-recording__dot voice-recording__dot--blue" /><span
        class="voice-recording__dot voice-recording__dot--red"
      /><span class="voice-recording__dot voice-recording__dot--orange" /><span
        class="voice-recording__dot voice-recording__dot--green"
      />
    </div>

    <Button
      variant="secondary"
      outline
      class="voice-recording__btn"
      @click="$emit('close')"
    >
      {{ $t('close') }}
    </Button>
  </div>
</template>

<script setup lang="ts">
import { Icon } from '@shared/Icon';
import { Button } from '@shared/Button';

defineEmits(['close']);

const { t: $t } = useI18n();
</script>

<style lang="scss" src="./VoiceRecording.scss" />
