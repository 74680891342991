<template>
  <div
    :class="[
      'catalog-menu',
      {
        'is-opened': isOpened,
      },
    ]"
  >
    <button
      class="catalog-menu__back"
      @click="setCategoryBack"
    >
      <Icon name="arrow-thin-left" />

      <Transition
        name="fade-slide-up"
        mode="out-in"
      >
        <span :key="categoryCurrent?.parent?.id">
          {{ categoryCurrent?.isParent ? categoryCurrent?.parent?.name : categoryCurrent?.grandParent?.name }}
        </span>
      </Transition>
    </button>

    <div
      v-if="categories?.length"
      class="catalog-menu__body"
    >
      <SideCatalogMenuCategories
        :categories
        :categoryCurrent
        :categoryUrlPath
        isOpened
        @category:set="setCategory"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import SideCatalogMenuCategories from './SideCatalogMenuCategories.vue';
import type { ICategory } from '@types';
import { Icon } from '@shared/Icon';

interface IProps {
  isOpened?: boolean;
}

defineProps<IProps>();

const { getCategories } = useCatalog();

const { openSideMenuMobile, closeSideMenu } = useSideNav();

const $breakpoints = useBreakpoints();

const $route = useRoute();

const { t: $t } = useI18n();

const localePath = useLocalePath();

const { categories } = storeToRefs(useCatalogStore());

const categoryCurrent = ref();

const categoryUrlPath = computed(
  () =>
    `/books/${Array.isArray($route.params?.category) ? $route.params?.category?.join('/') : $route.params?.category}`
);

const getCategoryCurrent = (categories?: ICategory[], urlPath?: string, grandParent?: ICategory): ICategory => {
  const current = categories?.find((category) => urlPath?.includes(String(category.urlPath)));

  if (current) {
    if (current?.urlPath !== urlPath) {
      return getCategoryCurrent(current?.children, urlPath, current?.parent);
    } else {
      return {
        id: current?.id,
        name: current?.name,
        urlPath: current?.urlPath,
        isParent: !!current?.children?.length,
        parent: current?.parent || {
          id: null,
          name: $t('booksCatalog'),
          urlPath: '/books',
        },
        grandParent: grandParent || {
          id: null,
          name: $t('toMainMenu'),
          urlPath: null,
        },
      };
    }
  } else if (!current && urlPath) {
    return {
      id: null,
      name: $t('booksCatalog'),
      urlPath: '/books',
      isParent: true,
      parent: {
        id: null,
        name: $t('toMainMenu'),
        urlPath: null,
      },
    };
  } else {
    return {
      id: null,
      name: $t('toMainMenu'),
      urlPath: null,
      isParent: true,
    };
  }
};

const setCategory = (urlPath: string) => {
  categoryCurrent.value = getCategoryCurrent(categories.value, urlPath);

  if (!categoryCurrent.value?.isParent) {
    navigateTo(localePath(urlPath));
  }
};

const setCategoryBack = () => {
  if (!categoryCurrent.value?.parent?.urlPath) {
    if ($breakpoints.isSmaller('xl')) {
      openSideMenuMobile();
    } else {
      closeSideMenu();
    }
  } else {
    categoryCurrent.value = getCategoryCurrent(
      categories.value,
      categoryCurrent.value?.isParent
        ? categoryCurrent.value?.parent?.urlPath
        : categoryCurrent.value?.grandParent?.urlPath
    );
  }
};

watchEffect(() => {
  categoryCurrent.value = getCategoryCurrent(categories.value, categoryUrlPath.value) as any;
});

onMounted(() => {
  getCategories();
});
</script>

<style lang="scss" src="./SideCatalogMenu.scss" />
