<template>
  <div class="lang-switcher">
    <LocaleLink
      v-for="item in locales"
      :key="item.code"
      :href="switchLocalePath(item.code)"
      :class="[
        'lang-switcher__item',
        {
          'is-active': isLocaleActive(item),
        },
      ]"
      @click="changeLocale(item)"
    >
      {{ item.nameShort }}
    </LocaleLink>
  </div>
</template>

<script lang="ts" setup>
import { setLocale as setLocaleVeeValidate } from '@vee-validate/i18n';
import { LocaleLink } from '@shared/LocaleLink';

const switchLocalePath = useSwitchLocalePath();

const { locale, locales, setLocale } = useI18n();

const isLocaleActive = computed(() => (item: any) => locale.value === item.code);

const changeLocale = (locale: any) => {
  setLocale(locale.code);
  setLocaleVeeValidate(locale.code);
};
</script>

<style lang="scss" src="./LangSwitcher.scss" />
