<template>
  <div class="contacts-info">
    <h3 class="contacts-info__title">[{{ $t('footer.contact') }}]</h3>

    <a
      v-if="store?.storePhone"
      :href="`tel:${store.storePhone}`"
      class="contacts-info__telephone"
    >
      {{ store.storePhone }}
    </a>

    <div
      v-if="store?.storeHours"
      class="contacts-info__schedule"
    >
      <p>{{ store.storeHours }}</p>
    </div>

    <p
      v-if="store?.storeEmail"
      class="contacts-info__email"
    >
      Email:
      <a
        :href="`mailto:${store.storeEmail}`"
        target="_blank"
        rel="nofollow noopener"
        class="contacts-info__link"
      >
        {{ store.storeEmail }}
      </a>
    </p>
  </div>
</template>

<script type="ts" setup>
const { store } = storeToRefs(useMainStore());
</script>

<style lang="scss" src="./ContactInfo.scss" />
