<template>
  <div class="mobile-menu">
    <Button
      :to="isAuth ? localePath('/cabinet/account') : undefined"
      :class="[
        'mobile-menu__auth',
        {
          'is-auth': isMounted && isAuth && !pendingUser,
          'has-avatar': isMounted && isAuth && !pendingUser && !!userAvatar,
        },
      ]"
      variant="link"
      block
      :loading="!isMounted || pendingUser"
      @click.prevent="!isAuth ? openAuthModal() : undefined"
    >
      <template #iconPrepend>
        <span
          v-if="isMounted && !!userAvatar"
          class="icon"
        >
          {{ userAvatar }}
        </span>

        <Icon
          v-else
          :name="isAuth ? 'user' : 'user-o'"
        />
      </template>

      {{ isAuth ? $t('welcome') : $t('authPersonal') }}
      <template v-if="isMounted && isAuth && userFullName"><br />{{ userFullName }}</template>
    </Button>

    <div class="mobile-menu__section">
      <Button
        class="mobile-menu__action mobile-menu__action--catalog"
        @click="openSideMenuCatalog"
      >
        <template #iconPrepend>
          <Icon name="book" />
        </template>

        {{ $t('booksCatalog') }}

        <template #icon>
          <Icon
            name="arrow-right"
            class="arrow"
          />
        </template>
      </Button>

      <Button
        class="mobile-menu__action"
        @click="openCart"
      >
        <template #iconPrepend>
          <Icon name="cart" />

          <ClientOnly>
            <Badge
              v-if="cart?.productsQuantityCount"
              class="mobile-menu__action-badge"
              size="sm"
              variant="primary"
            >
              {{ cart.productsQuantityCount }}
            </Badge>
          </ClientOnly>
        </template>

        {{ $t('cart.title') }}
      </Button>

      <Button
        :to="isAuth ? localePath('/cabinet/wishlist') : undefined"
        class="mobile-menu__action"
        :loading="!isMounted || pendingWishlist || pendingUser"
        @click.prevent="!isAuth ? openAuthModalWishlist() : undefined"
      >
        <template #iconPrepend>
          <Icon name="favorite" />
        </template>

        {{ $t('wishlist') }}
      </Button>
    </div>

    <ClientOnly>
      <template v-if="isMobile">
        <template v-if="menuBottom?.length">
          <hr />

          <div
            v-if="menuBottom"
            class="mobile-menu__section"
          >
            <Menu
              :data="menuBottom"
              variant="side"
            />
          </div>
        </template>

        <hr />

        <div
          v-if="headerNav"
          class="mobile-menu__section"
        >
          <Menu
            :data="headerNav"
            variant="side"
          />
        </div>
      </template>
    </ClientOnly>

    <div class="mobile-menu__footer">
      <ContactInfo />
      <SocialLinks />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Button } from '@shared/Button';
import { Badge } from '@shared/Badge';
import { Icon } from '@shared/Icon';
import { Menu } from '@entities/Menu';
import { ContactInfo } from '@entities/ContactInfo';
import { SocialLinks } from '@entities/SocialLinks';

const { trackViewedCart } = useAnalytics();

const { openSideMenuCatalog, closeSideMenu } = useSideNav();

const { openModalCart } = useCart();

const { openAuthModal } = useAuth();

const localePath = useLocalePath();

const { t: $t } = useI18n();

const $breakpoints = useBreakpoints();

const { cart } = storeToRefs(useCartStore());

const { isAuth } = storeToRefs(useAuthStore());

const { pending: pendingUser, userAvatar, userFullName } = storeToRefs(useUserStore());

const { pending: pendingWishlist } = storeToRefs(useWishlistStore());

const { headerNav } = storeToRefs(useMainStore());

const menuBottom = ref();

const isMobile = ref(false);

const isMounted = ref(false);

const openCart = () => {
  openModalCart({ callback: closeSideMenu });
  trackViewedCart();
};

const openAuthModalWishlist = () => {
  openAuthModal(() => navigateTo(localePath('/cabinet/wishlist')));
};

watch(
  $breakpoints.smaller('xl'),
  (value) => {
    isMobile.value = value;
  },
  {
    deep: true,
    immediate: true,
  }
);

onMounted(() => {
  isMounted.value = true;
});
</script>

<style lang="scss" src="./SideMobileMenu.scss" />
