<template>
  <SideNav />

  <Header />

  <QuickNav />

  <slot />

  <Footer />

  <ClientOnly>
    <ModalAuth v-if="!isAuth" />
  </ClientOnly>

  <ClientOnly>
    <ModalCart />
  </ClientOnly>
</template>

<script lang="ts" setup>
import { hydrateOnVisible } from 'vue';
import { SideNav } from '@widgets/SideNav';
import { Header } from '@widgets/Header';
import { QuickNav } from '@widgets/QuickNav';
import { Footer } from '@widgets/Footer';

const ModalAuth = defineAsyncComponent({
  loader: () => import('@features/ModalAuth/ui/ModalAuth.vue'),
  hydrate: hydrateOnVisible(),
});

const ModalCart = defineAsyncComponent({
  loader: () => import('@features/ModalCart/ui/ModalCart.vue'),
  hydrate: hydrateOnVisible(),
});

const { isAuth } = storeToRefs(useAuthStore());
</script>
