<template>
  <footer class="footer">
    <div class="footer__main">
      <div class="container container--md footer__main-container">
        <div class="footer__info">
          <div class="footer__info-wrap">
            <LocaleLink
              to="/"
              class="footer__logo"
            >
              <img
                :src="'/images/logo-footer.svg'"
                :alt="$t('logoAlt')"
                :title="$t('logoAlt')"
                loading="lazy"
                width="133"
                height="77"
              />
            </LocaleLink>

            <SocialLinks />
          </div>

          <ContactInfo class="footer__contacts" />
        </div>

        <div
          v-for="(section, idx) in footerNav"
          :key="`section-${idx}`"
          class="footer__nav"
        >
          <h3 class="footer__nav-title">
            {{ section.title }}
          </h3>

          <Menu
            v-if="section.submenu"
            :data="section.submenu"
            variant="footer"
          />
        </div>

        <SubscriptionNews class="footer__subscription-news" />
      </div>
    </div>

    <div class="footer__extra">
      <div class="container container--md footer__extra-container">
        <div class="footer__copyright">© 2024 {{ store?.siteName }}. {{ $t('allRightsReserved') }}.</div>

        <div class="footer__privacy">
          <LocaleLink
            :to="`/pages/${URLKEY_PAGE_CONDITIONS}`"
            class="menu__link"
          >
            {{ $t('publicOffer') }}
          </LocaleLink>

          <LocaleLink
            :to="`/pages/${URLKEY_PAGE_POLICY}`"
            class="menu__link"
          >
            {{ $t('privacyPolicy') }}
          </LocaleLink>
        </div>

        <div class="footer__pay">
          <a
            href="https://mastercard.com/"
            aria-label="Mastercard"
            rel="nofollow noopener"
          >
            <Icon name="mastercard" />
          </a>

          <a
            href="https://visa.com/"
            aria-label="Visa"
            rel="nofollow noopener"
          >
            <Icon name="visa" />
          </a>
        </div>

        <p class="footer__designed">Designed by WebLife</p>
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import { Icon } from '@shared/Icon';
import { Menu } from '@entities/Menu';
import { LocaleLink } from '@shared/LocaleLink';
import { SocialLinks } from '@entities/SocialLinks';
import { ContactInfo } from '@entities/ContactInfo';
import { SubscriptionNews } from '@features/SubscriptionNews';
import { URLKEY_PAGE_CONDITIONS, URLKEY_PAGE_POLICY } from '@constants';

const { t: $t } = useI18n();

const { store, footerNav } = storeToRefs(useMainStore());
</script>

<style lang="scss" src="./Footer.scss" />
