<template>
  <ul
    :class="[
      'catalog-menu__categories',
      {
        'is-opened': isOpened,
      },
    ]"
  >
    <LocaleLink
      :to="{ path: categoryCurrent?.isParent ? categoryCurrent?.urlPath : categoryCurrent?.parent?.urlPath }"
      class="catalog-menu__title"
    >
      {{ categoryCurrent?.isParent ? categoryCurrent?.name : categoryCurrent?.parent?.name }}
    </LocaleLink>

    <li
      v-for="category in categories"
      :key="category.id"
    >
      <LocaleLink
        :to="{ path: category.urlPath }"
        :class="{
          'is-parent': category?.childrenCount > 0,
          'is-active': category?.urlPath === categoryUrlPath,
        }"
        external
        @click.prevent="setCategory($event, category)"
      >
        {{ category.name }}
      </LocaleLink>

      <SideCatalogMenuCategories
        v-if="category?.childrenCount > 0"
        :categories="category.children"
        :categoryCurrent
        :categoryUrlPath
        :isOpened="
          (!categoryCurrent?.isParent && categoryCurrent?.parent?.urlPath === category.urlPath) ||
          categoryCurrent?.urlPath === category.urlPath
        "
        @category:set="$emit('category:set', $event)"
      />
    </li>
  </ul>
</template>

<script lang="ts" setup>
import { LocaleLink } from '@shared/LocaleLink';

interface IProps {
  categories: any;
  categoryCurrent?: any;
  categoryUrlPath: string;
  isOpened?: boolean;
}

defineProps<IProps>();

const $emit = defineEmits(['category:set']);

const setCategory = ($event: any, category: any) => {
  $emit('category:set', category.urlPath);

  $event.target.offsetParent.scrollTop = 0;
  $event.target.nextSibling.scrollTop = 0;
};
</script>

<style lang="scss" src="./SideCatalogMenuCategories.scss" />
