<template>
  <Drawer
    name="sideNav"
    :class="[
      'side-nav',
      {
        'is-opened-catalog': isSideMenuCatalogOpened,
      },
    ]"
  >
    <div class="side-nav__header">
      <LocaleLink
        to="/"
        class="side-nav__logo"
      >
        <img
          :src="'/images/logo.svg'"
          :alt="$t('logoAlt')"
          :title="$t('logoAlt')"
          loading="lazy"
          width="100"
          height="30"
        />
      </LocaleLink>

      <LangSwitcher />
    </div>

    <SideMobileMenu />

    <SideCatalogMenu :isOpened="isSideMenuCatalogOpened" />
  </Drawer>
</template>

<script lang="ts" setup>
import { SideMobileMenu } from '@features/SideMobileMenu';
import { SideCatalogMenu } from '@features/SideCatalogMenu';
import { LangSwitcher } from '@entities/LangSwitcher';
import { LocaleLink } from '@shared/LocaleLink';
import { Drawer } from '@shared/Drawer';

const $route = useRoute();

const $breakpoints = useBreakpoints();

const { closeModalCart } = useCart();

const { closeSideMenu } = useSideNav();

const { isSideMenuOpened, isSideMenuCatalogOpened } = storeToRefs(useSideNavStore());

watch(
  () => isSideMenuOpened.value,
  (value: boolean) => {
    if (value) {
      closeModalCart();
    }
  },
  {
    deep: true,
  }
);

watch(
  () => [$breakpoints.xl.value, $route.name],
  () => {
    closeSideMenu();
  }
);
</script>

<style lang="scss" src="./SideNav.scss" />
