<template>
  <div class="header-top">
    <div class="container">
      <Menu
        v-if="headerNav"
        :data="headerNav"
        variant="horizontal"
        color="blue"
      />

      <LangSwitcher />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { LangSwitcher } from '@entities/LangSwitcher';
import { Menu } from '@entities/Menu';

const { headerNav } = storeToRefs(useMainStore());
</script>

<style lang="scss" src="./HeaderTop.scss" />
