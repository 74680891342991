<template>
  <nav
    v-if="menuItems?.length"
    class="quick-nav"
  >
    <div class="container quick-nav__container">
      <Menu
        :data="menuItems"
        variant="horizontal"
      />
    </div>
  </nav>
</template>

<script lang="ts" setup>
import { Menu } from '@entities/Menu';

const menuItems = ref();
</script>

<style lang="scss" src="./QuickNav.scss" />
